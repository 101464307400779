<template>
  <!-- <gf-view title="Price Movement">
    <template slot="toolbar">
      <el-select size="small" v-model="toolbar.view" style="margin-right: 10px;">
        <el-option :value="1" label="Table"></el-option>
        <el-option :value="2" label="Graph"></el-option>
      </el-select>
      <el-select size="small" v-model="toolbar.type" style="margin-right: 10px;" @change="getPriceHistory">
        <el-option :value="1" label="Price History"></el-option>
        <el-option :value="2" label="Transaction"></el-option>
      </el-select>
      <el-select size="small" v-model="toolbar.priceType" v-if="toolbar.type === 1" @change="getPriceHistory">
        <el-option :value="1" label="Buy"></el-option>
        <el-option :value="2" label="Wholesale"></el-option>
        <el-option :value="3" label="Retail"></el-option>
      </el-select>
      <el-select size="small" v-model="toolbar.transactionType" v-else @change="getPriceHistory">
        <el-option :value="1" label="Buy"></el-option>
        <el-option :value="2" label="Sell"></el-option>
      </el-select>
    </template>
    <div class="container">
      <div class="date-selection">
        <el-date-picker format="dd MMM yyyy" value-format="yyyy-MM-dd" size="small" type="daterange" start-placeholder="Start Date" end-placeholder="End Date" v-model="cycle.dateRange" @change="getPriceHistory"></el-date-picker>
      </div>
      <el-table v-if="toolbar.view === 1 && toolbar.type === 1" :data="priceHistories" header-cell-class-name="cell-header--dark" cell-class-name="cell-style">
        <el-table-column label="Date">
          <template slot-scope="slot">
            {{ $dateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Time">
          <template slot-scope="slot">
            {{ $getTime(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Price">
          <template slot-scope="slot">
            {{ $numberFormat(slot.row.value) }}
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else-if="toolbar.view === 1 && toolbar.type === 2" :data="transactionHistories" header-cell-class-name="cell-header--dark" cell-class-name="cell-style">
        <el-table-column label="Date">
          <template slot-scope="slot">
            {{ $dateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Time">
          <template slot-scope="slot">
            {{ $getTime(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column :label="toolbar.transactionType === 1 ? 'Supplier' : 'Customer'">
          <template slot-scope="slot">
            {{ slot.row.company.name }}
          </template>
        </el-table-column>
        <el-table-column label="Price">
          <template slot-scope="slot">
            {{ $numberFormat(slot.row.value) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="graph"  v-else>
        <price-graph :chart-data="priceChart"></price-graph>
      </div>
      <template slot="footer" v-if="toolbar.view === 1">
        <el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="page.number" :page-size="page.size" @current-change="getPriceHistory">
        </el-pagination>
      </template>
    </div>
  </gf-view> -->
  <gf-content title="Price Movement" subtitle="Variant Price Changes per Transaction">
    <template #toolbar>
      <el-select v-model="toolbar.view" style="margin-right: 10px;">
        <el-option :value="1" label="Table"></el-option>
        <el-option :value="2" label="Graph"></el-option>
      </el-select>
      <el-select v-model="toolbar.type" style="margin-right: 10px;" @change="getPriceHistory">
        <el-option :value="1" label="Price History"></el-option>
        <el-option :value="2" label="Transaction"></el-option>
      </el-select>
      <el-select v-model="toolbar.priceType" v-if="toolbar.type === 1" @change="getPriceHistory">
        <el-option :value="1" label="Buy"></el-option>
        <el-option :value="2" label="Wholesale"></el-option>
        <el-option :value="3" label="Retail"></el-option>
      </el-select>
      <el-select v-model="toolbar.transactionType" v-else @change="getPriceHistory">
        <el-option :value="1" label="Buy"></el-option>
        <el-option :value="2" label="Sell"></el-option>
      </el-select>
    </template>
    <template #header>
      <div class="mt-2">
        <span class="f-13 mr-2">Date Range :</span>
        <el-date-picker format="dd MMM yyyy" value-format="yyyy-MM-dd" size="small" type="daterange" start-placeholder="Start Date" end-placeholder="End Date" v-model="cycle.dateRange" @change="getPriceHistory"></el-date-picker>
      </div>
    </template>
  </gf-content>
</template>

<style lang="scss" scoped>
.container {
  padding: 0 20px;
  flex: 1;
}

.date-selection {
  text-align: right;
  padding: 10px 0;
}

.graph {
  padding: 0 20px;
}
</style>

<script>
import PriceHistoryService from '@/services/v1/PriceHistory'
import TransactionHistoryService from '@/services/v1/TransactionHistory'
// import PriceGraph from './graphs/PriceGraph'

export default {
  components: {
    // PriceGraph
  },
  data () {
    return {
      toolbar: {
        view: 1,
        type: 1,
        transactionType: 1,
        priceType: 1
      },
      cycle: {
        type: 1, // 1 - daily, 2 - weekly, 3 - monthly
        dateRange: [this.$dateISOFormat(new Date()), this.$dateISOFormat(new Date())]
      },
      priceHistories: [],
      transactionHistories: [],

      // pagination
      total: 0,
      page: {
        size: 20,
        number: 1
      }
    }
  },
  computed: {
    priceChart () {
      const labels = []
      const dataset = {
        label: 'Price',
        borderColor: '#409EFF',
        backgroundColor: 'rgba(64, 158, 255, .2)',
        data: []
      }

      if (this.toolbar.type === 1) {
        for (let i = 0; i < this.priceHistories.length; i++) {
          const price = this.priceHistories[i]
          labels.push(this.$dateFormat(price.created_at))
          dataset.data.push(price.value)
        }
      } else {
        for (let i = 0; i < this.transactionHistories.length; i++) {
          const price = this.transactionHistories[i]
          labels.push(this.$dateFormat(price.created_at))
          dataset.data.push(price.value)
        }
      }

      return {
        labels,
        datasets: [dataset]
      }
    }
  },
  methods: {
    async getPriceHistory () {
      try {
        const productId = this.$route.params.id
        const variantId = this.$route.params.vid

        const queryUrl = {
          'page[size]': this.page.size,
          'page[number]': this.page.number,
          arg: null,
          from: this.cycle.dateRange[0],
          to: this.cycle.dateRange[1]
        }

        let result = null
        if (this.toolbar.type === 1) {
          queryUrl.arg = this.toolbar.priceType
          const phService = new PriceHistoryService(productId, variantId)
          result = await phService.get(queryUrl)
          this.priceHistories = result.data.rows
        } else {
          queryUrl.arg = this.toolbar.transactionType
          const thService = new TransactionHistoryService(productId, variantId)
          result = await thService.get(queryUrl)
          this.transactionHistories = result.data.rows
        }

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.total = result.data.count
      } catch (error) {
        this.$error500()
      }
    }
  },
  mounted () {
    this.$emit('update:active', 3)
    this.getPriceHistory()
  }
}
</script>
